export const data = [
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» граждане Российской Федерации могут применять имеющееся у них на законных основаниях оружие:",
    items: [
      {
        title:
          "Для защиты чести и достоинства граждан при любой угрозе данным правоохраняемым интересам.",
        isRight: false,
      },
      {
        title:
          "Только для защиты жизни и здоровья в состоянии необходимой обороны или крайней необходимости.",
        isRight: false,
      },
      {
        title:
          "Для защиты жизни, здоровья и собственности в состоянии необходимой обороны или крайней необходимости.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» применению оружия должно предшествовать четко выраженное предупреждение об этом лица, против которого применяется оружие:",
    items: [
      {
        title: "Во всех случаях применения оружия",
        isRight: false,
      },
      {
        title:
          "Кроме случаев, когда правонарушитель скрывается с места правонарушения",
        isRight: false,
      },
      {
        title:
          "За исключением случаев, когда промедление в применении оружия создает непосредственную опасность для жизни людей или может повлечь за собой иные тяжкие последствия",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» применение огнестрельного оружия гражданами в отношении женщин, лиц с явными признаками инвалидности и несовершеннолетних, когда их возраст очевиден или известен допускается:",
    items: [
      {
        title:
          "В случае применения оружия при ограниченной видимости вследствие погодных условий.",
        isRight: false,
      },
      {
        title: "В случае значительного скопления людей.",
        isRight: false,
      },
      {
        title:
          "В случае совершения указанными лицами группового или вооруженного нападения.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Обязаны ли граждане (за исключением специально уполномоченных лиц), являющиеся пассажирами воздушного судна гражданской авиации, сдавать имеющееся у них оружие уполномоченным лицам для временного хранения на период полета?",
    items: [
      {
        title: "Обязаны во всех случаях.",
        isRight: true,
      },
      {
        title: "Обязаны, кроме случаев, когда оружие находится в его багаже.",
        isRight: false,
      },
      {
        title: "Не обязаны.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "При необходимой обороне субъектом посягательства, отражаемого обороняющимся, является:",
    items: [
      {
        title: "Человек (физическое лицо).",
        isRight: true,
      },
      {
        title: "Стихия (силы природы).",
        isRight: false,
      },
      {
        title: "Источник повышенной опасности (оружие, автомобиль и пр.).",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Могут ли действия граждан по защите других лиц расцениваться как действия в состоянии необходимой обороны:",
    items: [
      {
        title: "Не могут ни при каких условиях.",
        isRight: false,
      },
      {
        title:
          "Могут, если соблюдены условия необходимой обороны, предусмотренные законом.",
        isRight: true,
      },
      {
        title: "Могут только в случаях непосредственной угрозы жизни.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Допускается ли причинение вреда третьим лицам в состоянии необходимой обороны?",
    items: [
      {
        title: "Да, при групповом нападении.",
        isRight: false,
      },
      {
        title: "Да, при вооруженном нападении.",
        isRight: false,
      },
      {
        title: "Нет.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Гражданским кодексом РФ вред, причиненный в состоянии крайней необходимости:",
    items: [
      {
        title: "Не подлежит возмещению",
        isRight: false,
      },
      {
        title:
          "Во всех случаях подлежит возмещению в полном объеме лицом, причинившим вред",
        isRight: false,
      },
      {
        title: "Подлежит возмещению по решению суда",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Причинение вреда, менее значительного, чем предотвращенный вред, является обязательным условием правомерности действий:",
    items: [
      {
        title: "В состоянии необходимой обороны",
        isRight: false,
      },
      {
        title: "В состоянии крайней необходимости",
        isRight: true,
      },
      {
        title:
          "Как в состоянии необходимой обороны, так и в состоянии крайней необходимости",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Небрежное хранение огнестрельного оружия, создавшее условия для его использования другим лицом, если это повлекло смерть человека или иные тяжкие последствия, влечет:",
    items: [
      {
        title: "Уголовную ответственность.",
        isRight: true,
      },
      {
        title: "Административную ответственность.",
        isRight: false,
      },
      {
        title: "Уголовную и административную ответственность.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Нарушение гражданами правил ношения оружия и патронов к нему влечет:",
    items: [
      {
        title: "Уголовную ответственность",
        isRight: false,
      },
      {
        title: "Административную ответственность",
        isRight: true,
      },
      {
        title: "Уголовную и административную ответственность",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» охотничье пневматическое оружие может иметь дульную энергию:",
    items: [
      {
        title: "Не более 3 Дж",
        isRight: false,
      },
      {
        title: "Не более 7,5 Дж",
        isRight: false,
      },
      {
        title: "Не более 25 Дж",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В какой срок и куда в соответствии с Федеральным законом «Об оружии» владелец оружия обязан сообщить о каждом случае его применения?",
    items: [
      {
        title:
          "Незамедлительно, но не позднее суток, прокурору и в органы здравоохранения, по месту применения оружия",
        isRight: false,
      },
      {
        title:
          "Незамедлительно, но не позднее суток, в орган внутренних дел и территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, по месту применения оружия.",
        isRight: true,
      },
      {
        title:
          "Незамедлительно, но не позднее 6 часов, прокурору, в органы здравоохранения, в орган внутренних дел и территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, по месту применения оружия.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Обязан ли гражданин незамедлительно информировать орган внутренних дел и территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, в случае, если он применил оружие для производства предупредительного выстрела?",
    items: [
      {
        title:
          "Обязан информировать орган внутренних дел и территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, по месту применения оружия",
        isRight: true,
      },
      {
        title: "Не обязан, поскольку нет пострадавших",
        isRight: false,
      },
      {
        title:
          "Обязан информировать орган внутренних дел и территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, по месту регистрации оружия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Правилами оборота гражданского и служебного оружия на территории Российской Федерации предусмотрено, что досылание патрона в патронник разрешается:",
    items: [
      {
        title:
          "Только при необходимости применения оружия либо для защиты жизни, здоровья и собственности в состоянии необходимой обороны или крайней необходимости",
        isRight: true,
      },
      {
        title:
          "При необходимости применения оружия, а также в любых других опасных ситуациях",
        isRight: false,
      },
      {
        title:
          "При необходимости применения оружия, а также при охране денежных средств и ценных грузов",
        isRight: false,
      },
    ],
  },
  {
    title:
      "На основании Правил оборота гражданского и служебного оружия на территории Российской Федерации граждане, осуществляющие ношение оружия, должны иметь при себе:",
    items: [
      {
        title:
          "Документы, удостоверяющие их личность, паспорт на оружие от предприятия-производителя",
        isRight: false,
      },
      {
        title:
          "Выданное органом внутренних дел разрешение на хранение и ношение имеющегося у них оружия, медицинскую справку формы 002-О/у",
        isRight: false,
      },
      {
        title:
          "Документы, удостоверяющие их личность (паспорт или служебное удостоверение, военный или охотничий билет и т.п.), а также выданные территориальным органом исполнительной власти, уполномоченного в сфере оборота оружия, лицензию либо разрешение на хранение и ношение имеющегося у них оружия.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» к основным частям огнестрельного оружия относятся:",
    items: [
      {
        title: "Ствол, затвор, барабан, рамка, ствольная коробка",
        isRight: true,
      },
      {
        title:
          "Ствол, затворная рама, крышка ствольной коробки, приклад, рукоятка",
        isRight: false,
      },
      {
        title: "Ствол, магазин, барабан, рамка, ствольная коробка, патрон",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» к огнестрельному оружию ограниченного поражения могут быть отнесены:",
    items: [
      {
        title:
          "Короткоствольное оружие и длинноствольное оружие, использующее патроны травматического действия",
        isRight: false,
      },
      {
        title:
          "Короткоствольное оружие и бесствольное оружие, использующее патроны травматического действия",
        isRight: true,
      },
      {
        title:
          "Короткоствольное, длинноствольное оружие и бесствольное оружие, использующее патроны травматического действия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» к газовому оружию относится:",
    items: [
      {
        title:
          "Оружие, предназначенное для поражения цели на расстоянии снарядом, получающим направленное движение за счет энергии сжатого, сжиженного или отвержденного газа",
        isRight: false,
      },
      {
        title:
          "Оружие, предназначенное для временного поражения живой цели путем применения слезоточивых, раздражающих веществ, или патронов травматического действия",
        isRight: false,
      },
      {
        title:
          "Оружие, предназначенное для временного химического поражения живой цели путем применения слезоточивых или раздражающих веществ",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Гражданским кодексом Российской Федерации вред, причиненный в состоянии необходимой обороны:",
    items: [
      {
        title:
          "Подлежит возмещению, только если превышает установленный законом минимальный размер оплаты труда",
        isRight: false,
      },
      {
        title:
          "Не подлежит возмещению, если при этом не были превышены пределы необходимой обороны",
        isRight: true,
      },
      {
        title: "В любом случае подлежит возмещению лицом, причинившим вред",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» общее количество приобретенного гражданином Российской Федерации огнестрельного оружия ограниченного поражения (за исключением случаев, если указанное оружие является объектом коллекционирования) не должно превышать:",
    items: [
      {
        title: "Две единицы",
        isRight: true,
      },
      {
        title: "Три единицы",
        isRight: false,
      },
      {
        title: "Пять единиц",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» общее количество приобретенного гражданином Российской Федерации огнестрельного гладкоствольного длинноствольного оружия (за исключением случаев, если указанное оружие является объектом коллекционирования) не должно превышать:",
    items: [
      {
        title: "Две единицы",
        isRight: false,
      },
      {
        title: "Три единицы",
        isRight: false,
      },
      {
        title: "Пять единиц",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» по лицензии на приобретение газовых пистолетов, револьверов допускается их регистрация в количестве:",
    items: [
      {
        title: "Не более двух единиц",
        isRight: false,
      },
      {
        title: "Не более трех единиц",
        isRight: false,
      },
      {
        title: "Не более пяти единиц",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» лицензия на приобретение оружия не выдается гражданам Российской Федерации, совершившим повторно в течение года:",
    items: [
      {
        title: "Любое административное правонарушение",
        isRight: false,
      },
      {
        title:
          "Административное правонарушение, посягающее на институты государственной власти, либо административное правонарушение, посягающее на права граждан",
        isRight: false,
      },
      {
        title:
          "Административное правонарушение, посягающее на общественный порядок и общественную безопасность или установленный порядок управления, административное правонарушение, связанное с нарушением правил охоты, либо административное правонарушение в области незаконного оборота наркотических средств, психотропных веществ или их аналогов и потребления без назначения врача наркотических средств или психотропных веществ (до окончания срока, в течение которого лицо считается подвергнутым административному наказанию);",
        isRight: true,
      },
    ],
  },
  {
    title:
      "За стрельбу из оружия в отведенных для этого местах с нарушением установленных правил:",
    items: [
      {
        title: "Не предусмотрено административного наказания",
        isRight: false,
      },
      {
        title:
          "Предусмотрено предупреждение или наложение административного штрафа",
        isRight: false,
      },
      {
        title:
          "Предусмотрено наложение административного штрафа с конфискацией оружия и патронов к нему или без таковой",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Федеральным законом «Об оружии» предусмотрена регистрация приобретенного огнестрельного оружия, огнестрельного оружия ограниченного поражения и охотничьего пневматического оружия с дульной энергией свыше 7,5 Дж, а также газовых пистолетов и револьверов, в территориальном органе федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, по месту жительства:",
    items: [
      {
        title: "В двухнедельный срок",
        isRight: true,
      },
      {
        title: "В месячный срок",
        isRight: false,
      },
      {
        title: "В трехмесячный срок",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Заведомое оставление без помощи лица, находящегося в опасном для жизни или здоровья состоянии и лишенного возможности принять меры к самосохранению (в том случае, если виновный имел возможность оказать помощь этому лицу и сам поставил его в опасное для жизни или здоровья состояние) является:",
    items: [
      {
        title: "Преступлением",
        isRight: true,
      },
      {
        title: "Административным правонарушением",
        isRight: false,
      },
      {
        title: "Деянием, ответственность за которое не предусмотрена",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Нарушение гражданином установленных сроков регистрации приобретенного по лицензиям федерального органа исполнительной власти, осуществляющего функции в сфере деятельности войск национальной гвардии Российской Федерации, или его территориального органа оружия, а равно установленных сроков продления (перерегистрации) разрешений (открытых лицензий) на его хранение и ношение или сроков постановки оружия на учет в федеральном органе исполнительной власти, осуществляющем функции в сфере деятельности войск национальной гвардии Российской Федерации, или его территориальном органе при изменении гражданином постоянного места жительства, влечет:",
    items: [
      {
        title: "Уголовную ответственность",
        isRight: false,
      },
      {
        title: "Административную ответственность",
        isRight: true,
      },
      {
        title: "Гражданско-правовую ответственность",
        isRight: false,
      },
    ],
  },
  {
    title:
      "За стрельбу из оружия в населенных пунктах или в других не отведенных для этого местах:",
    items: [
      {
        title: "Не предусмотрено административного наказания",
        isRight: false,
      },
      {
        title:
          "Предусмотрено предупреждение или наложение административного штрафа",
        isRight: false,
      },
      {
        title:
          "Предусмотрено наложение административного штрафа с конфискацией оружия и патронов к нему либо лишение права на приобретение и хранение или хранение и ношение оружия на срок от полутора до трех лет с конфискацией оружия и патронов к нему",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Результаты прохождения проверки знания правил безопасного обращения с оружием и наличия навыков безопасного обращения с оружием оформляются:",
    items: [
      {
        title: "Актом прохождения проверки",
        isRight: true,
      },
      {
        title: "Свидетельством о прохождении проверки",
        isRight: false,
      },
      {
        title: "Экзаменационным листом",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Лицензия на приобретение оружия и разрешение на хранение или хранение и ношение оружия аннулируются:",
    items: [
      {
        title: "Органами местного самоуправления.",
        isRight: false,
      },
      {
        title: "Органами, уполномоченные на выдачу охотничьих билетов.",
        isRight: false,
      },
      {
        title: "Органами, выдавшими эти лицензию и (или) разрешение.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Проверка знания правил безопасного обращения с оружием и наличия навыков безопасного обращения с оружием владельцами огнестрельного оружия ограниченного поражения, газовых пистолетов, револьверов, гражданского огнестрельного гладкоствольного длинноствольного оружия самообороны проводится:",
    items: [
      {
        title: "Не реже одного раза в год",
        isRight: false,
      },
      {
        title: "Не реже одного раза в три года",
        isRight: false,
      },
      {
        title: "Не реже одного раза в пять лет",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Для продления срока действия лицензий, а также разрешений на хранение, хранение и использование, хранение и ношение оружия их владельцы представляют необходимые заявления и документы в территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, по месту учета оружия:",
    items: [
      {
        title: "Не позднее, чем за месяц до истечения срока действия",
        isRight: true,
      },
      {
        title: "Не позднее, чем за два месяца до истечения срока действия",
        isRight: false,
      },
      {
        title: "Не позднее, чем за три месяца до истечения срока действия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "При выдаче новых лицензий и разрешений ранее полученные, с истекшим сроком действия:",
    items: [
      {
        title: "Остаются у владельца оружия",
        isRight: false,
      },
      {
        title:
          "Подлежат сдаче в территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия.",
        isRight: true,
      },
      {
        title:
          "Подлежат сдаче в территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия, только если выдавались на огнестрельное оружие.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "По достижении какого возраста граждане Российской Федерации имеют право на приобретение газового оружия, спортивного оружия, охотничьего огнестрельного гладкоствольного длинноствольного и охотничьего огнестрельного длиноствольного оружия с нарезным стволом для занятия спортом, сигнального оружия, холодного клинкового оружия, предназначенного для ношения с национальными костюмами народов Российской Федерации или казачьей формой?",
    items: [
      {
        title: "16 лет.",
        isRight: false,
      },
      {
        title: "18 лет.",
        isRight: true,
      },
      {
        title: "25 лет.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Возраст, по достижении которого граждане Российской Федерации могут получить разрешения на хранение или хранение и ношение охотничьего огнестрельного гладкоствольного оружия:",
    items: [
      {
        title:
          "Может быть снижен по решению законодательного (представительного) органа государственной власти субъекта Российской Федерации, не более чем на два года.",
        isRight: true,
      },
      {
        title:
          "Может быть снижен по решению законодательного (представительного) органа государственной власти субъекта Российской Федерации, не более чем на один год.",
        isRight: false,
      },
      {
        title: "Не может быть снижен.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» огнестрельное оружие ограниченного поражения иностранного производства, его основные части, а также патроны травматического действия, изготовленные за пределами территории Российской Федерации:",
    items: [
      {
        title:
          "Подлежат ввозу в Российскую Федерацию, если их технические характеристики соответствуют аналогичным моделям отечественного производства.",
        isRight: false,
      },
      {
        title: "Подлежат ввозу в Российскую Федерацию.",
        isRight: false,
      },
      {
        title: "Не подлежат ввозу в Российскую Федерацию.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В случае изменения места жительства гражданин Российской Федерации обязан обратиться с заявлением о постановке на учет принадлежащего ему оружия:",
    items: [
      {
        title:
          "В территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия по прежнему месту жительства в двухнедельный срок со дня регистрации.",
        isRight: false,
      },
      {
        title:
          "В соответствующий территориальный орган федерального органа исполнительной власти, уполномоченного в сфере оборота оружия по новому месту жительства в двухнедельный срок со дня регистрации.",
        isRight: true,
      },
      {
        title:
          "В территориальные органы федерального органа исполнительной власти, уполномоченного в сфере оборота оружия по прежнему, а также по новому месту жительства, в течении месяца со дня регистрации.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Оружие, находящееся на законных основаниях на праве личной собственности у гражданина Российской Федерации может быть продано другому гражданину, имеющему лицензию на приобретение оружия, его коллекционирование или экспонирование:",
    items: [
      {
        title:
          "После перерегистрации оружия в федеральном органе исполнительной власти, уполномоченном в сфере оборота оружия, или его территориальном органе по месту жительства лица, приобретающего оружие.",
        isRight: false,
      },
      {
        title:
          "После внесения записи о перерегистрации оружия в лицензию нового владельца самим гражданином, продающим оружие.",
        isRight: false,
      },
      {
        title:
          "После перерегистрации оружия в федеральном органе исполнительной власти, уполномоченном в сфере оборота оружия, или его территориальном органе по месту учета указанного оружия.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Согласно Правил оборота гражданского и служебного оружия и патронов к нему на территории Российской Федерации, принадлежащие гражданам Российской Федерации оружие и патроны по месту их проживания:",
    items: [
      {
        title:
          "Должны храниться с соблюдением условий, обеспечивающих их сохранность, безопасность хранения и исключающих доступ к ним посторонних лиц, в запирающихся на замок (замки) сейфах, сейфовых шкафах или металлических шкафах для хранения оружия, ящиках из высокопрочных материалов либо в деревянных ящиках, обитых железом.",
        isRight: true,
      },
      {
        title:
          "Должны храниться с соблюдением условий, обеспечивающих их сохранность, безопасность хранения и исключающих доступ к ним посторонних лиц, в любых запирающихся на замок ящиках.",
        isRight: false,
      },
      {
        title: "Хранятся в произвольном порядке.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Согласно Правил оборота гражданского и служебного оружия и патронов к нему на территории Российской Федерации, хранение оружия и патронов гражданами Российской Федерации в местах временного пребывания:",
    items: [
      {
        title:
          "Должно осуществляться с соблюдением условий, исключающих доступ к оружию посторонних лиц.",
        isRight: true,
      },
      {
        title:
          "Должно осуществляться с соблюдением условий, исключающих доступ к оружию посторонних лиц и только в запирающихся на замок (замки) сейфах или металлических шкафах для хранения оружия, ящиках из высокопрочных материалов либо в деревянных ящиках, обитых железом.",
        isRight: false,
      },
      {
        title: "Осуществляется в произвольном порядке.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Хранение оружия и патронов, принадлежащего гражданам Российской Федерации, являющихся членами спортивных стрелковых обществ и клубов на спортивных стрелково-стендовых объектах по месту проведения тренировочных стрельб и соревнований:",
    items: [
      {
        title: "Допускается.",
        isRight: true,
      },
      {
        title: "Не допускается.",
        isRight: false,
      },
      {
        title:
          "Допускается только при условии, что названные граждане являются спортсменами высокого класса.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Граждане Российской Федерации, которым в установленном порядке предоставлено право на охоту, имеют право приобретать охотничье огнестрельное оружие с нарезным стволом:",
    items: [
      {
        title:
          "При условии, что они занимаются профессиональной деятельностью, связанной с охотой, либо имеют в собственности охотничье огнестрельное гладкоствольное длинноствольное оружие не менее трех лет.",
        isRight: false,
      },
      {
        title:
          "При условии, что они занимаются профессиональной деятельностью, связанной с охотой, либо имеют в собственности охотничье огнестрельное гладкоствольное длинноствольное оружие не менее пяти лет.",
        isRight: true,
      },
      {
        title:
          "Только в случае, если они имеют в собственности охотничье огнестрельное гладкоствольное длинноствольное оружие не менее пяти лет.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Согласно Правил оборота гражданского и служебного оружия и патронов к нему на территории Российской Федерации, транспортирование принадлежащего гражданам оружия:",
    items: [
      {
        title: "Осуществляется в произвольном порядке.",
        isRight: false,
      },
      {
        title:
          "Осуществляется в кобурах, чехлах; при их отсутствии в ингибиторной бумаге.",
        isRight: false,
      },
      {
        title:
          "Осуществляется в чехлах, кобурах или специальных футлярах, а также в специальной упаковке производителя оружия.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Имеют ли право на необходимую оборону лица, имеющие возможность избежать общественно опасного посягательства или обратиться за помощью к другим лицам или органам власти?",
    items: [
      {
        title: "Да, имеют.",
        isRight: true,
      },
      {
        title: "Нет, не имеют.",
        isRight: false,
      },
      {
        title:
          "Имеют, если посягательство сопряжено с насилием, опасным для жизни обороняющегося.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Правилами оборота гражданского и служебного оружия и патронов к нему на территории Российской Федерации установлен следующий порядок ношения огнестрельного короткоствольного оружия:",
    items: [
      {
        title:
          "В кобуре, со снаряженным магазином или барабаном, поставленным на предохранитель (при наличии).",
        isRight: true,
      },
      {
        title: "В кобуре, с патроном в патроннике, со взведенным курком",
        isRight: false,
      },
      {
        title:
          "В кобуре, с патроном в патроннике, поставленным на предохранитель (при наличии).",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» на территории Российской Федерации ношение гражданами в целях самообороны огнестрельного длинноствольного оружия и холодного оружия:",
    items: [
      {
        title:
          "Разрешается на территории того субъекта Российской Федерации, в котором проживает владелец указанного оружия",
        isRight: false,
      },
      {
        title: "Разрешается на всей территории Российской Федерации",
        isRight: false,
      },
      {
        title:
          "Запрещается, за исключением случаев перевозки или транспортирования указанного оружия",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» гражданское огнестрельное оружие (за исключением спортивного оружия) должно иметь емкость магазина (барабана):",
    items: [
      {
        title: "Не более 8 патронов",
        isRight: false,
      },
      {
        title: "Не более 10 патронов",
        isRight: true,
      },
      {
        title: "Не более 12 патронов",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Требованиями охотничьего минимума установлено, что осуществление охоты не допускается:",
    items: [
      {
        title: "Ближе 15 метров от линий электропередач",
        isRight: false,
      },
      {
        title: "В одежде ярких расцветок",
        isRight: false,
      },
      {
        title: "В состоянии алкогольного, наркотического опьянения",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что в целях обеспечения безопасности при осуществлении охоты запрещается:",
    items: [
      {
        title: "Стрелять «на шум», «на шорох», по неясно видимой цели",
        isRight: true,
      },
      {
        title: "Стрелять, если цель находится на возвышенности",
        isRight: false,
      },
      {
        title: "Стрелять в болотистой местности",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что в целях обеспечения безопасности при осуществлении охоты запрещается осуществлять добычу охотничьих животных с применением охотничьего огнестрельного и (или) пневматического оружия ближе:",
    items: [
      {
        title: "100 метров от жилья",
        isRight: false,
      },
      {
        title: "200 метров от жилья",
        isRight: true,
      },
      {
        title: "300 метров от жилья",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что в целях обеспечения безопасности при осуществлении охоты запрещается:",
    items: [
      {
        title: "Стрелять по пернатой дичи, находящейся в стае",
        isRight: false,
      },
      {
        title: "Стрелять по пернатой дичи, сидящей на нижних ветках деревьев",
        isRight: false,
      },
      {
        title:
          "Стрелять по пернатой дичи, сидящей на проводах и опорах (столбах) линий электропередач",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что в целях обеспечения безопасности при осуществлении охоты запрещается стрелять вдоль линии стрелков, когда снаряд может пройти ближе:",
    items: [
      {
        title: "25 метров от соседнего стрелка",
        isRight: false,
      },
      {
        title: "20 метров от соседнего стрелка",
        isRight: false,
      },
      {
        title: "15 метров от соседнего стрелка",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что в целях обеспечения безопасности при осуществлении охоты запрещается организовывать загон охотничьих животных:",
    items: [
      {
        title:
          "При котором животных загоняют в направлении к реке или иному водоему",
        isRight: false,
      },
      {
        title:
          "При котором охотники движутся внутрь загона, окружая оказавшихся в загоне животных",
        isRight: true,
      },
      {
        title:
          "При котором загонщики используют какие-либо дополнительные средства производства шума, помимо собственного голоса",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что на коллективной охоте для добычи охотничьих животных запрещается:",
    items: [
      {
        title:
          "Применение полуавтоматического оружия с магазином вместимостью более пяти патронов",
        isRight: true,
      },
      {
        title:
          "Применение полуавтоматического оружия с магазином вместимостью более четырех патронов",
        isRight: false,
      },
      {
        title: "Применение любого полуавтоматического оружия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Правилами охоты установлено, что при осуществлении охоты запрещается применение охотничьего огнестрельного гладкоствольного оружия для охоты на пернатую дичь:",
    items: [
      {
        title:
          "Снаряженного дробью (картечью) крупнее пяти миллиметров и пулями",
        isRight: true,
      },
      {
        title:
          "Снаряженного дробью (картечью) крупнее четырех миллиметров и пулями",
        isRight: false,
      },
      {
        title:
          "Снаряженного дробью (картечью) крупнее трех миллиметров и пулями",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Кодексом РФ об административных правонарушениях нарушение правил пользования объектами животного мира и правил охоты влечет для граждан:",
    items: [
      {
        title:
          "Наложение административного штрафа с конфискацией орудий охоты или без таковой или административный арест до 15 суток",
        isRight: false,
      },
      {
        title:
          "Наложение административного штрафа с конфискацией орудий охоты или без таковой или лишение права осуществлять охоту на срок до двух лет",
        isRight: true,
      },
      {
        title: "Только аннулирование (изъятие) охотничьего билета",
        isRight: false,
      },
    ],
  },
  {
    title:
      "По достижении какого возраста граждане Российской Федерации (за исключением граждан, прошедших либо проходящих военную службу, а также граждан, проходящих службу в государственных военизированных организациях и имеющих воинские звания либо специальные звания или классные чины юстиции) имеют право на приобретение гражданского огнестрельного оружия ограниченного поражения, охотничьего оружия, огнестрельного гладкоствольного длинноствольного оружия самообороны?",
    items: [
      {
        title: "18 лет.",
        isRight: false,
      },
      {
        title: "21 год.",
        isRight: true,
      },
      {
        title: "25 лет.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Согласно Правил оборота гражданского и служебного оружия и патронов к нему на территории Российской Федерации, граждане Российской Федерации осуществляют ношение и использование оружия (с учетом ограничений, установленных Федеральным законом «Об оружии»):",
    items: [
      {
        title:
          "Во время охоты, проведения спортивных мероприятий, тренировочных и учебных стрельб, а также в целях самообороны.",
        isRight: true,
      },
      {
        title:
          "Во время охоты, проведения спортивных мероприятий, тренировочных и учебных стрельб.",
        isRight: false,
      },
      {
        title: "Только во время охоты и в целях самообороны.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» осмотр врачом-психиатром и врачом-психиатром-наркологом при проведении медицинского освидетельствования на наличие медицинских противопоказаний к владению оружием и химико-токсикологические исследования наличия в организме человека наркотических средств, психотропных веществ и их метаболитов осуществляется:",
    items: [
      {
        title: "В любых медицинских организациях.",
        isRight: false,
      },
      {
        title:
          "В медицинских организациях государственной или муниципальной системы здравоохранения только по месту жительства гражданина Российской Федерации.",
        isRight: false,
      },
      {
        title:
          "В медицинских организациях государственной или муниципальной системы здравоохранения по месту жительства (пребывания) гражданина Российской Федерации.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» запрещается ношение огнестрельного оружия:",
    items: [
      {
        title: "На стрелковых объектах.",
        isRight: false,
      },
      {
        title: "В состоянии опьянения.",
        isRight: true,
      },
      {
        title:
          "За пределами субъекта Российской Федерации, где зарегистрировано огнестрельное оружие.",
        isRight: false,
      },
    ],
  },
  {
    title:
      "В соответствии с Федеральным законом «Об оружии» запрещается ношение гражданами огнестрельного оружия ограниченного поражения:",
    items: [
      {
        title: "На стрелковых объектах.",
        isRight: false,
      },
      {
        title:
          "Во время нахождения в организациях, предназначенных для развлечения и досуга (независимо от времени их работы и факта реализации в них алкогольной продукции), за исключением случаев ношения такого оружия лицами, осуществляющими в соответствии с законодательством Российской Федерации охрану указанных организаций.",
        isRight: false,
      },
      {
        title:
          "Во время нахождения в организациях, предназначенных для развлечения и досуга, осуществляющих работу в ночное время и реализующих алкогольную продукцию, за исключением случаев ношения такого оружия лицами, осуществляющими в соответствии с законодательством Российской Федерации охрану указанных организаций.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Продажа инициирующих и воспламеняющих веществ и материалов (порох, капсюли) для самостоятельного снаряжения патронов к гражданскому огнестрельному длинноствольному оружию либо для заряжания оружия, имеющего культурную ценность, копий старинного (антикварного) огнестрельного оружия и реплик старинного (антикварного) огнестрельного оружия разрешается:",
    items: [
      {
        title: "Любым гражданам, достигшим возраста 18 лет.",
        isRight: false,
      },
      {
        title:
          "Гражданам, проходящим службу в государственных военизированных организациях и имеющим воинские звания либо специальные звания или классные чины юстиции, а также гражданам, представившим разрешение на хранение и ношение гражданского огнестрельного длинноствольного оружия.",
        isRight: false,
      },
      {
        title:
          ". Гражданам, представившим разрешение на хранение и ношение гражданского огнестрельного длинноствольного оружия.",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Не подлежат продаже вещества и материалы для самостоятельного снаряжения патронов к гражданскому огнестрельному длинноствольному оружию:",
    items: [
      {
        title: "Произведенные за пределами Российской Федерации.",
        isRight: false,
      },
      {
        title:
          "Упаковка которых не содержит сведений о правилах их безопасного использования для самостоятельного снаряжения патронов к гражданскому огнестрельному длинноствольному оружию.",
        isRight: true,
      },
      {
        title: "Вес упаковки которых превышает 100 гр.",
        isRight: false,
      },
    ],
  },
  {
    title: "Линией прицеливания называется:",
    items: [
      {
        title: "Линия, проходящая от центра ствола в точку прицеливания",
        isRight: false,
      },
      {
        title:
          "Прямая линия, проходящая от глаза стрелка через середину прорези прицела (на уровне с ее краями) и вершину мушки в точку прицеливания",
        isRight: true,
      },
      {
        title: "Линия, описываемая центром тяжести пули в полете",
        isRight: false,
      },
    ],
  },
  {
    title: "Траекторией полета пули называется:",
    items: [
      {
        title: "Кривая линия, описываемая центром тяжести пули в полете",
        isRight: true,
      },
      {
        title:
          "Прямая линия, проходящая от глаза стрелка через середину прорези прицела (на уровне с ее краями) и вершину мушки в точку прицеливания",
        isRight: false,
      },
      {
        title: "Прямая линия от центра ствола до точки попадания",
        isRight: false,
      },
    ],
  },
  {
    title: "Прямым выстрелом называется:",
    items: [
      {
        title:
          "Выстрел, при котором траектория полета пули поднимается над линией прицеливания выше цели не более, чем на одной трети своего протяжения",
        isRight: false,
      },
      {
        title:
          "Выстрел, при котором ствол оружия и линия плеч стрелка составляют прямой угол",
        isRight: false,
      },
      {
        title:
          "Выстрел, при котором траектория полета пули не поднимается над линией прицеливания выше цели на всем своем протяжении",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Каков порядок действий стрелка при проведении стрельб в тирах и на стрельбищах?",
    items: [
      {
        title:
          "Стрелок самостоятельно выходит на линию огня, по команде «Заряжай» заряжает оружие и по команде «Огонь» ведет огонь",
        isRight: false,
      },
      {
        title:
          "Стрелок по команде «На линию огня» выходит на огневой рубеж, самостоятельно заряжает, стреляет",
        isRight: false,
      },
      {
        title:
          "Стрелок выходит, заряжает, стреляет, производит иные действия только по мере получения отдельных команд",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Какова предельная дальность полета пуль из огнестрельного гладкоствольного длинноствольного оружия 12 калибра?",
    items: [
      {
        title: "1000 — 1500 метров",
        isRight: true,
      },
      {
        title: "300 — 500 метров",
        isRight: false,
      },
      {
        title: "100 — 300 метров",
        isRight: false,
      },
    ],
  },
  {
    title: "В случае задержки при стрельбе из пистолета в тире необходимо:",
    items: [
      {
        title:
          "Осторожно вынуть магазин из основания рукоятки, устранить причину задержки, продолжить выполнение упражнения",
        isRight: false,
      },
      {
        title:
          "Поставить оружие на предохранитель, вынуть магазин из основания рукоятки, сдать оружие руководителю стрельб (инструктору)",
        isRight: false,
      },
      {
        title:
          "Не производить никаких действий с оружием и удерживая его в направлении мишени, доложить руководителю стрельб (инструктору) о задержке и действовать по его команде",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Безопасное использование оружия предполагает в период непосредственного применения:",
    items: [
      {
        title:
          "Держать указательный палец вдоль спусковой скобы, переставляя его на спусковой крючок только перед выстрелом",
        isRight: true,
      },
      {
        title: "Держать указательный палец всегда на спусковом крючке",
        isRight: false,
      },
      {
        title:
          "Удерживая оружие двумя руками, всегда держать указательные пальцы (один на другом) на спусковом крючке",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Безопасное использование оружия предполагает в период непосредственного применения:",
    items: [
      {
        title:
          "Ни при каких обстоятельствах не ставить оружие на предохранитель",
        isRight: false,
      },
      {
        title:
          "Не ставить оружие на предохранитель после досылания патрона в патронник, даже если оружие не применяется сразу после досылания патрона",
        isRight: false,
      },
      {
        title:
          "Ставить оружие на предохранитель после досылания патрона в патронник, если оружие не применяется сразу после досылания патрона",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Безопасное использование оружия предполагает в период непосредственного применения:",
    items: [
      {
        title:
          "При досылании патрона в патронник и при расчете траектории выстрела не отвлекаться на контроль направления ствола оружия",
        isRight: false,
      },
      {
        title:
          "Осуществлять обязательный контроль направления ствола оружия при досылании патрона в патронник и при расчете траектории выстрела, для исключения возможного вреда самому владельцу оружия, посторонним лицам и имуществу",
        isRight: true,
      },
      {
        title:
          "Осуществлять контроль направления ствола оружия при досылании патрона в патронник и рассчитывать траекторию выстрела только в ситуациях близости несовершеннолетних или ценного имущества",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Безопасное использование оружия при его ношении предполагает передачу оружия лицу, уполномоченному на его проверку:",
    items: [
      {
        title: "С патроном в патроннике и присоединенным магазином",
        isRight: false,
      },
      {
        title:
          "С отсоединенным магазином и после проверки факта отсутствия патрона в патроннике",
        isRight: true,
      },
      {
        title: "В том состоянии, которого потребовал проверяющий",
        isRight: false,
      },
    ],
  },
  {
    title:
      "При стрельбе в тире в противошумовых наушниках или защитных очках действуют следующие правила:",
    items: [
      {
        title: "Следует закрепить их во избежание падения во время стрельбы",
        isRight: false,
      },
      {
        title:
          "Запрещается надевать, поправлять и снимать их с оружием в руках",
        isRight: true,
      },
      {
        title:
          "Разрешается надевать, поправлять и снимать их с оружием в руках",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Во время перемещения по тиру или стрельбищу (осмотр мишеней и т.п.) в соответствии с мерами по обеспечению безопасности:",
    items: [
      {
        title: "Оружие должно находиться в руках стрелка",
        isRight: false,
      },
      {
        title:
          "Оружие должно находиться в кобуре стрелка или на столике стрелка в разряженном и поставленном на предохранитель виде",
        isRight: true,
      },
      {
        title:
          "Оружие по усмотрению стрелка либо находится в руках стрелка, либо помещается в кобуру стрелка",
        isRight: false,
      },
    ],
  },
  {
    title: "Отдачей оружия называется:",
    items: [
      {
        title:
          "Подбрасывание ствола оружия в вертикальной плоскости при выстреле",
        isRight: false,
      },
      {
        title:
          "Движение ствола и связанных с ним деталей оружия в сторону, противоположную движению снаряда (пули) во время выстрела",
        isRight: true,
      },
      {
        title:
          "Передача разряженного и поставленного на предохранитель оружия инструктору (руководителю стрельбы) по окончании стрельб",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Согласно рекомендациям предприятий-производителей после стрельбы из пистолетов (револьверов) газовыми патронами их чистка производится:",
    items: [
      {
        title: "Сухой тканью",
        isRight: false,
      },
      {
        title: "Тканью, смоченной спиртом или спиртовым раствором",
        isRight: true,
      },
      {
        title: "Тканью, смоченной ружейной смазкой",
        isRight: false,
      },
    ],
  },
  {
    title: "Смазку оружия положено производить:",
    items: [
      {
        title: "Одновременно с чисткой",
        isRight: false,
      },
      {
        title: "По истечении 10 минут после чистки",
        isRight: false,
      },
      {
        title: "Немедленно после чистки",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Для эффективного поражения цели предполагается ведение огня (в зависимости от дистанции):",
    items: [
      {
        title: "На дистанции, не превышающей рекомендуемую для данного оружия",
        isRight: true,
      },
      {
        title:
          "На любой дистанции (в том числе и превышающей рекомендуемую для данного оружия)",
        isRight: false,
      },
      {
        title:
          "На дистанции, не превышающей максимальную дальность полета пули из данного оружия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Произойдет ли выстрел, если гражданин дослал патрон в патронник пистолета, передернув затвор и сразу поставил его на предохранитель (А при этом курок сорвался с боевого взвода!)?",
    items: [
      {
        title: "Произойдет, как и при любом срыве курка с боевого взвода",
        isRight: false,
      },
      {
        title: "Произойдет, но с замедлением до 30 секунд",
        isRight: false,
      },
      {
        title:
          "Не произойдет, пока не будет произведен повторный взвод и спуск курка при снятом предохранителе",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Меры безопасности при проведении на стрелковых объектах специальных упражнений (связанных с поворотами, разворотами, кувырками, перекатами и т.п.) предполагают:",
    items: [
      {
        title:
          "Обязательное применение оружия, предназначенного для подразделений специального назначения",
        isRight: false,
      },
      {
        title:
          "Нахождение оружия на предохранителе вплоть до момента открытия огня, направление оружия и производство выстрелов только по мишеням, установленным перед пулеприемником (пулеприемниками)",
        isRight: true,
      },
      {
        title:
          "Надежное удержание оружия при передвижениях, без каких-либо дополнительных требований",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Для временного прекращения стрельбы в тире (на стрельбище) подается команда:",
    items: [
      {
        title: "«Оружие к осмотру»",
        isRight: false,
      },
      {
        title: "«Разряжай»",
        isRight: false,
      },
      {
        title: "«Стой»",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Действия по временному прекращению стрельбы в тире (на стрельбище) или в ходе применения оружия гражданином в ситуациях необходимой обороны или крайней необходимости:",
    items: [
      {
        title:
          "Прекратить нажим на хвост спускового крючка; извлечь магазин (для оружия, имеющего магазин), произвести контрольный спуск курка (в условиях безопасности по направлению возможного выстрела), включить предохранитель (если таковой имеется)",
        isRight: false,
      },
      {
        title:
          "Прекратить нажим на хвост спускового крючка; включить предохранитель (если таковой имеется); при необходимости – перезарядить оружие",
        isRight: true,
      },
      {
        title:
          "Прекратить нажим на хвост спускового крючка; извлечь магазин (для оружия, имеющего магазин), произвести контрольный спуск курка (в условиях безопасности по направлению возможного выстрела)",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Для полного прекращения стрельбы в тире (на стрельбище) подается команда:",
    items: [
      {
        title: "«Оружие к осмотру»",
        isRight: false,
      },
      {
        title: "«Разряжай»",
        isRight: true,
      },
      {
        title: "«Стой»",
        isRight: false,
      },
    ],
  },
  {
    title: "Действия по полному прекращению стрельбы в тире (на стрельбище):",
    items: [
      {
        title:
          "Прекратить нажим на хвост спускового крючка, включить предохранитель (если таковой имеется), разрядить оружие (согласно правилам, установленным для данного оружия), далее произвести контрольный спуск курка (в условиях безопасности по направлению возможного выстрела)",
        isRight: false,
      },
      {
        title:
          "Прекратить нажим на хвост спускового крючка; включить предохранитель (если таковой имеется)",
        isRight: false,
      },
      {
        title:
          "Прекратить нажим на хвост спускового крючка, включить предохранитель (если таковой имеется), разрядить оружие (согласно правилам, установленным для данного оружия); далее действовать по команде «Оружие – к осмотру»",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Действия с пистолетом при получении в тире (на стрельбище) команды «Оружие – к осмотру»:",
    items: [
      {
        title:
          "Извлечь магазин, предъявить оружие к осмотру (в положении «на затворной задержке») с приложенным сбоку (под большой палец правой руки) магазином. После осмотра оружия руководителем стрельбы (по команде «осмотрено») поставить оружие на предохранитель; магазин вставить в основание рукоятки, пистолет вложить в кобуру",
        isRight: false,
      },
      {
        title:
          "Извлечь магазин, предъявить оружие к осмотру (в положении «на затворной задержке») с приложенным сбоку (под большой палец правой руки) магазином. После осмотра оружия руководителем стрельбы (по команде «осмотрено») снять с затворной задержки, произвести контрольный спуск курка, поставить оружие на предохранитель; магазин вставить в основание рукоятки, пистолет вложить в кобуру",
        isRight: true,
      },
      {
        title:
          "Извлечь магазин, предъявить оружие к осмотру (в положении «на затворной задержке») с приложенным сбоку (под большой палец правой руки) магазином. После осмотра оружия руководителем стрельбы (по команде «осмотрено») снять с затворной задержки, произвести контрольный спуск курка, поставить оружие на предохранитель",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Действия с оружием по завершении его применения гражданином в ситуациях необходимой обороны или крайней необходимости (до прибытия правоохранительных органов):",
    items: [
      {
        title:
          "Прекратить нажим на хвост спускового крючка, и не ставя оружие на предохранитель, дождаться указаний представителей правоохранительных органов",
        isRight: false,
      },
      {
        title:
          "Прекратить нажим на хвост спускового крючка, разрядить оружие, включить предохранитель (если таковой имеется), убрать оружие в кобуру (для тех видов оружия, ношение которых осуществляется в кобуре)",
        isRight: false,
      },
      {
        title:
          "Прекратить нажим на хвост спускового крючка; не разряжая оружие, включить предохранитель (если таковой имеется), убрать оружие в кобуру (для тех видов оружия, ношение которых осуществляется в кобуре)",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Тактика действий вооруженного обороняющегося при агрессивном поведении большой группы людей предполагает:",
    items: [
      {
        title:
          "Применять оружие сразу, не пытаясь покинуть место конфликта или нейтрализовать конфликт",
        isRight: false,
      },
      {
        title:
          "Покинуть место конфликта, при невозможности этого попытаться нейтрализовать агрессию путем переговоров; принимать решение на применение оружие только с учетом целесообразности его применения",
        isRight: true,
      },
      {
        title:
          "В указанной ситуации не применять оружие ни при каких обстоятельствах",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Тактика действий при наличии на траектории стрельбы третьих лиц, не участвующих в нападении:",
    items: [
      {
        title:
          "Обороняющийся, сам оставаясь в неподвижности, ожидает изменения ситуации (подавая третьим лицам соответствующие команды голосом)",
        isRight: false,
      },
      {
        title:
          "Обороняющийся перемещается в направлении (влево, вправо, вниз или вверх), обеспечивающем безопасную для третьих лиц траекторию выстрела",
        isRight: true,
      },
      {
        title:
          "Обороняющийся не отвлекается на действия, направленные на обеспечение безопасности третьих лиц",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Что применяется в качестве дополнительной меры по обеспечению сохранности огнестрельного короткоствольного оружия при его ношении:",
    items: [
      {
        title: "Использование пистолетного (револьверного) шнура",
        isRight: true,
      },
      {
        title: "Обматывание оружия изоляционной лентой",
        isRight: false,
      },
      {
        title: "Ношение патронов отдельно от оружия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Если при осмотре гильз на них обнаружены дефекты (микротрещина, незначительное вздутие гильзы), как Вы поступите с гильзой?",
    items: [
      {
        title: "Утилизирую (выкину)",
        isRight: true,
      },
      {
        title:
          "Если небольшая трещина буду использовать для дальнейшей сборки патрона",
        isRight: false,
      },
      {
        title: "Постараюсь заделать трещину или обжать гильзу",
        isRight: false,
      },
    ],
  },
  {
    title: "На что может повлиять чрезмерный заряд пороха в патроне?",
    items: [
      {
        title: "На увеличение дальности стрельбы",
        isRight: false,
      },
      {
        title: "На кучность попаданий при стрельбе",
        isRight: false,
      },
      {
        title: "На приведение в негодность оружия (разрыв ствола и его частей)",
        isRight: true,
      },
    ],
  },
  {
    title:
      "На что может повлиять некорректная (слабая) посадка капсюля в гильзе?",
    items: [
      {
        title: "Разрыв патрона",
        isRight: false,
      },
      {
        title: "Осечка при выстреле",
        isRight: false,
      },
      {
        title: "Блокировка затвора оружия",
        isRight: true,
      },
    ],
  },
  {
    title: "На что может повлиять чрезмерная посадка капсюля в гильзе?",
    items: [
      {
        title: "Преждевременная инициация капсюля",
        isRight: false,
      },
      {
        title: "Осечка при выстреле",
        isRight: true,
      },
      {
        title: "Разрыв ствола и других частей оружия",
        isRight: false,
      },
    ],
  },
  {
    title:
      "На что влияют необрезанные гильзы для огнестрельного оружия с нарезным стволом?",
    items: [
      {
        title: "Затяжной выстрел",
        isRight: false,
      },
      {
        title: "Осечка при выстреле",
        isRight: false,
      },
      {
        title: "Точность выстрела",
        isRight: true,
      },
    ],
  },
  {
    title:
      "Возможно ли при осуществлении посадки капсюля в гильзу наносить по капсюлю удары какими-либо предметами?",
    items: [
      {
        title: "Да, возможно",
        isRight: false,
      },
      {
        title: "Недопустимо и опасно",
        isRight: true,
      },
      {
        title: "Возможно через мягкую ткань или прокладку",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Влияет ли на точность (кучность) стрельбы обрезка пуль горлышком гильзы?",
    items: [
      {
        title: "Не влияет",
        isRight: false,
      },
      {
        title: "Влияет",
        isRight: true,
      },
      {
        title: "Обрезка вообще не предусмотрена",
        isRight: false,
      },
    ],
  },
  {
    title: "К чему приведет недостаточная обжимка среза горлышка гильзы?",
    items: [
      {
        title: "К заеданию в системе подачи патронов из магазина",
        isRight: true,
      },
      {
        title: "К детонации капсюля",
        isRight: false,
      },
      {
        title: "Не имеет значения при сборке патрона",
        isRight: false,
      },
    ],
  },
  {
    title:
      "Допускается ли одновременно держать на рабочем столе капсюли и порох при сборке патронов?",
    items: [
      {
        title: "Допускается",
        isRight: false,
      },
      {
        title: "Не допускается и опасно",
        isRight: true,
      },
      {
        title: "Не имеет значения при сборке патронов",
        isRight: false,
      },
    ],
  },
];
